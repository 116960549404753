import * as React from "react";
import "./index.scss";

interface AboutValuesType {
  content?: any;
}

function formatDateThreeDaysAgo() {
  let date = new Date();
  date.setDate(date.getDate() - 3);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  const formattedDate = `Update: ${day} ${month} ${year}`;
  return formattedDate;
}

const Content: React.FC<AboutValuesType> = ({ content }) => {
  const [dateUpdate] = React.useState(formatDateThreeDaysAgo());
  return (
    <section className="content-values">
      <div className="container">
        <h3>{dateUpdate}</h3>
        <p
          className="content-values-desc"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </section>
  );
};

export default Content;
